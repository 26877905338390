
import { Component, Vue } from "vue-property-decorator";
import { Validations } from "vuelidate-property-decorators";
import { required, numeric } from "vuelidate/lib/validators";

@Component
export default class MixinDomesticPostageFeeEditDialogValidator extends Vue {
  @Validations()
  validations = {
    item: {
      //都道府県
      prefecture: { required },
      //送料
      amount: { required, numeric },
      //表示順
      displayOrder: { required, numeric },
    },
  };
  //---------------------------
  // methods
  //---------------------------

  /**
   * 都道府県入力エラーメッセージを返します.
   */
  get prefectureErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.item.prefecture?.$dirty) return errors;
    !this.$v.item.prefecture?.required &&
      errors.push("都道府県を入力してください.");
    return errors;
  }

  /**
   * 送料入力エラーメッセージを返します.
   */
  get amountErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.item.amount?.$dirty) return errors;
    !this.$v.item.amount?.required && errors.push("送料を入力してください.");
    !this.$v.item.amount?.numeric && errors.push("数値を入力してください.");
    return errors;
  }

  /**
   * 表示順入力エラーメッセージを返します.
   */
  get displayOrderErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.item.displayOrder?.$dirty) return errors;
    !this.$v.item.displayOrder?.required &&
      errors.push("表示順を入力してください.");
    !this.$v.item.displayOrder?.numeric &&
      errors.push("数値を入力してください.");
    return errors;
  }
}
