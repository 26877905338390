
















































import { Component, Ref, Vue } from "vue-property-decorator";
import { NotificationState } from "@/store/modules/notification";
import domesticPostageFeeService from "@/service/domesticPostageFeeService";
import DimesticPostageFeeEditDialog from "@/components/organisms/domestic_postage_fee/DimesticPostageFeeEditDialog.vue";
import DimesticPostageFeeDeleteDialog from "@/components/organisms/domestic_postage_fee/DimesticPostageFeeDeleteDialog.vue";
import { DomesticPostageFee } from "@/graphql/client";

@Component({
  components: { DimesticPostageFeeEditDialog, DimesticPostageFeeDeleteDialog },
})
export default class DomesticPostageFeeList extends Vue {
  @Ref() readonly editDialog!: DimesticPostageFeeEditDialog;
  @Ref() readonly deleteDialog!: DimesticPostageFeeDeleteDialog;
  //---------------------------
  // data
  //---------------------------
  items: DomesticPostageFee[] = [];
  headers = [
    {
      text: "都道府県",
      value: "prefecture",
    },
    {
      text: "送料",
      value: "amount",
      width: "15%",
    },
    {
      text: "表示順",
      value: "displayOrder",
      width: "15%",
    },
    {
      text: "",
      value: "editAction",
      width: "30px",
      sortable: false,
    },
    {
      text: "",
      value: "deleteAction",
      width: "30px",
      sortable: false,
    },
  ];

  loadingDataGrid = false;

  //---------------------------
  // mounted
  //---------------------------
  mounted(): void {
    this.fetchData();
  }

  //---------------------------
  // computed
  //---------------------------
  //---------------------------
  // methods
  //---------------------------
  async fetchData(): Promise<void> {
    this.loadingDataGrid = true;
    domesticPostageFeeService.allDomesticPostageFees().then((items) => {
      this.items = items;
      this.loadingDataGrid = false;
    });
  }

  /**
   * 地域別送料編集ダイアログ（新規追加）を表示します.
   */
  public openCreateDialog(): void {
    this.editDialog.open(domesticPostageFeeService.defaultDomesticPostageFee);
  }

  /**
   * 地域別送料編集ダイアログを表示します.
   */
  public openEditDialog(item: DomesticPostageFee): void {
    this.editDialog.open(item);
  }

  /**
   * 地域別送料編集ダイアログの更新成功時.
   */
  public onUpdateSuccess(updated: DomesticPostageFee): void {
    //一覧にも反映
    const index = this.items.findIndex((el) => {
      return el.guid === updated.guid;
    });
    if (index > -1) {
      this.items.splice(index, 1, updated);
    }

    this.editDialog.close();
    NotificationState.notifySuccess("地域別送料を更新しました！");
  }

  /**
   * 地域別送料編集ダイアログの登録成功時.
   */
  public onCreateSuccess(created: DomesticPostageFee): void {
    //一覧にも追加
    this.items.unshift(created);

    this.editDialog.close();
    NotificationState.notifySuccess("地域別送料を登録しました！");
  }

  /**
   * お知らせ削除ダイアログを表示します.
   */
  public openDeleteDialog(item: DomesticPostageFee): void {
    this.deleteDialog.open(item);
  }

  /**
   * お知らせ削除ダイアログの処理成功時.
   */
  public onDeleteSuccess(deleted: DomesticPostageFee): void {
    //一覧からも削除
    const newItems = this.items.filter((item) => item.guid !== deleted.guid);
    this.items = newItems;

    this.deleteDialog.close();
    NotificationState.notifySuccess("地域別送料を削除しました！");
  }
}
