import { DomesticPostageFee } from "@/graphql/client";
import { sdk } from "@/plugins/graphql-provider";

/**
 * 地域別送料に関する機能を提供します.
 */
class DomesticPostageFeeService {
  /**
   * 初期状態の 地域別送料
   */
  public get defaultDomesticPostageFee(): DomesticPostageFee {
    return {
      guid: "",
      prefecture: "",
      amount: 0,
      displayOrder: 0,
      createdAt: "",
      updatedAt: "",
    } as DomesticPostageFee;
  }

  /**
   * すべての地域別送料を取得して返します.
   * @returns すべての地域別送料
   */
  public async allDomesticPostageFees() {
    const response = await sdk.domesticPostageFees();
    if (response?.domesticPostageFees) {
      return response.domesticPostageFees as DomesticPostageFee[];
    }
    return [] as DomesticPostageFee[];
  }

  /**
   * 指定したGUIDに一致する地域別送料を取得して返します.
   * @param guid 地域別送料のGUID
   * @returns 地域別送料
   */
  public async getDomesticPostageFee(guid: string) {
    const response = await sdk.domesticPostageFee({ domesticPostageFeeKey: { guid: guid }});
    return response.domesticPostageFee as DomesticPostageFee;
  }

  /**
   * 地域別送料を登録します.
   * @param input 地域別送料の登録情報
   * @returns 登録完了後の地域別送料
   */
  public async createDomesticPostageFee(input: DomesticPostageFee) {
    try {
      const response = await sdk.createDomesticPostageFee({
        createDomesticPostageFeeInput: {
          prefecture: input.prefecture,
          amount: input.amount,
          displayOrder: input.displayOrder,
        },
      });

      if (response?.createDomesticPostageFee) {
        return response.createDomesticPostageFee;
      }
    } catch (err) {
      console.log(err);
    }
  }

  /**
   * 地域別送料を更新します.
   * @param input 地域別送料の更新情報
   * @returns 更新完了後の地域別送料
   */
  public async updateDomesticPostageFee(input: DomesticPostageFee) {
    try {
      const response = await sdk.updateDomesticPostageFee({
        updateDomesticPostageFeeInput: {
          guid: input.guid,
          prefecture: input.prefecture,
          amount: input.amount,
          displayOrder: input.displayOrder,
        },
      });

      if (response?.updateDomesticPostageFee) {
        return response.updateDomesticPostageFee;
      }
    } catch (err) {
      console.log(err);
    }
  }

  /**
   * 地域別送料を削除します.
   * @param input 地域別送料の削除情報
   * @returns 削除完了後の地域別送料
   */
  public async deleteDomesticPostageFee(input: DomesticPostageFee) {
    try {
      const response = await sdk.deleteDomesticPostageFee({
        deleteDomesticPostageFeeInput: {
          guid: input.guid,
        },
      });

      if (response?.deleteDomesticPostageFee) {
        return response.deleteDomesticPostageFee;
      }
    } catch (err) {
      console.log(err);
    }
  }
}

export default new DomesticPostageFeeService();
