










import SubPageTemplate from "@/components/templates/SubPageTemplate.vue";
import DimesticPostageFeeList from "@/components/organisms/domestic_postage_fee/DimesticPostageFeeList.vue";

export default {
  components: {
    SubPageTemplate,
    DimesticPostageFeeList,
  },
};
